.video {
    &-container {
        height: 100%;
        position: relative;
        text-align: center;
    }
    &-screen {
        height: 100%;
        display: inline-block;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: center;
        &-shade {
            width: 100%;
            height: 100%;
            position: absolute;
            background: rgba(0, 0, 0, 0.4);
            display: none;

            &.scanning {
                display: unset;
            }
        }
        &-scan {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            transform: translate3d(-50%, -50%, 0);
            padding: 5vmax;

            &-lines {
                color: #ffffff;
                height: 0%;
                width: 100%;
                padding-bottom: 63.5%;

                background: linear-gradient(
                            to right,
                            #ffffff 1px,
                            transparent 2px
                        )
                        1% 1%,
                    linear-gradient(to right, #ffffff 1px, transparent 2px) 1%
                        99%,
                    linear-gradient(to left, #ffffff 1px, transparent 2px) 99%
                        1%,
                    linear-gradient(to left, #ffffff 1px, transparent 2px) 99%
                        99%,
                    linear-gradient(to bottom, #ffffff 1px, transparent 2px) 1%
                        1%,
                    linear-gradient(to bottom, #ffffff 1px, transparent 2px) 99%
                        1%,
                    linear-gradient(to top, #ffffff 1px, transparent 2px) 1% 99%,
                    linear-gradient(to top, #ffffff 1px, transparent 2px) 99%
                        99%;
                background-repeat: no-repeat;
                background-size: 50px 50px;
                box-sizing: border-box;
            }
        }
    }

    &-large {
        transition: 200ms;
        max-width: 100%;
        height: 100%;
    }
    &-float {
        display: flex;
        color: #000;
        background: #fff;
        position: absolute;
        border-radius: 5px;
        overflow: hidden;
        top: 5px;
        right: 5px;
        &-load {
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            position: absolute;
            font-size: 22px;
            display: none;

            &.loading {
                display: unset;
            }
        }
    }
    &-small {
        transition: 200ms;
        width: 40vw;
        max-width: 40vmin;
        max-height: 40vmin;
    }
}

#btnHangUp {
    position: absolute;
    bottom: 4em;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 4px;
    padding: 8px;
}

#local-video + .video-feedback {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.video-freeze {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 1;
    transform: translate(-50%, -50%);
    transition: filter 500ms ease-in-out;
    filter: blur(12px);
    background-color: transparent;
}

.video-freeze[data-show='false'] {
    opacity: 0;
    filter: blur(0px);
}
