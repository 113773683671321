.video-container {
    background-color: #000;
}

#btnHangUp {
    position: fixed;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 0);
}
