.select {
    position: relative;
    height: 40px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 0 3em 0 1em;
    font-size: 14px;
    border-radius: 4px;
    border-style: solid;
    border-color: #e9eaf1;
    border-width: 2px;
}

.select-options {
    position: absolute;
    top: 100%;
    left: -2px;
    width: calc(100% + 4px);

    border-width: 2px;
    border-style: solid;
    border-color: #e9eaf1;

    &:not([data-open='true']) {
        display: none;
    }
}

.select-option {
    padding: 0.5em 1.5em 0.5em 1em;
    background-color: #fff;

    &:hover {
        background-color: #e9eaf1;
    }

    &:not(:last-child) {
        border-bottom: 2px solid #e9eaf1;
    }
}

.select-icon {
    position: absolute;
    right: 1em;
}
