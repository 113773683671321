.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.7);
}

.overlay-body {
    background-color: #fff;
    height: 100%;
    padding: 2em;
}

@media (min-width: 480px) {
    .overlay-body {
        height: auto;
        margin: 0 64px;
        border-radius: 4px;
        padding: 2em 4em;
        max-height: 80%;
    }
}

.message-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: inline-block;

    padding: 0;
    border-radius: 12px;

    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;

    width: auto;
    height: auto;

    .overlay-body {
        background: none;
        padding: 2em;
    }
}
