@import '~bootstrap/scss/bootstrap.scss';
@import './App.scss';
@import './VideoChat.scss';

@import 'overlay';
@import 'page';
@import 'video';
@import 'select';

.max-50-vh {
    max-height: 50vh;
}

.max-80-vw {
    max-width: 80vh;
}
