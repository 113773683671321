html,
body,
#root {
    height: 100vh;
    width: 100vw;
}

body {
    // color: #fff;
    // background: black;
}

.App {
    height: 100%;
    width: 100%;
    position: fixed;
    .body {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        text-align: center;
    }

    .utility {
        &-container {
            border-radius: 5px;
            position: absolute;
            bottom: 0px;
            left: 0px;
            background: rgba(0, 0, 0, 0.3);
            padding: 4px;
        }
        &-text {
            font-size: 22px;
            padding: 0px 4px;
        }
        &-pre-id {
            display: inline;
        }
        &-id {
            display: inline-block;
            min-width: 4em;
            color: dodgerblue;
        }
        &-buttons {
            text-align: center;
            & .btn {
                margin: 0px 4px;
            }
        }
    }
}

#settings-button {
    background-color: unset;
    border: none;
    outline: none;
    position: absolute;
    top: 1em;
    right: 1em;
}
